import { Component, HostBinding, HostListener } from '@angular/core';

import { ThemeService } from './../../../_services/theme/theme.service';

@Component({
	selector: 'xcu-theme-toggle,button[xcu-theme-toggle]',
	host: {
		type: 'button',
	},
	templateUrl: './theme-toggle.component.html',
	styleUrls: ['./theme-toggle.component.scss'],
})
export class ThemeToggleComponent {
	@HostBinding('class.isLight')
	public get isLight(): boolean {
		return this.themeService.isLight;
	}

	public constructor(public themeService: ThemeService) {}

	@HostListener('click', ['$event'])
	public onClick(): void {
		this.themeService.toggle();
	}
}
