import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StripeSeparatorComponent } from './stripe-separator.component';

@NgModule({
	declarations: [StripeSeparatorComponent],
	imports: [CommonModule],
	exports: [StripeSeparatorComponent],
})
export class StripeSeparatorModule {}
