import { Component } from '@angular/core';

@Component({
	selector: 'xcu-stripe-separator, div[xcu-stripe-separator], span[xcu-stripe-separator]',
	templateUrl: './stripe-separator.component.html',
	styleUrls: ['./stripe-separator.component.scss'],
})
export class StripeSeparatorComponent {
	public constructor() {}
}
