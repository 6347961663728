import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ThemeToggleComponent } from './theme-toggle.component';

@NgModule({
	declarations: [ThemeToggleComponent],
	imports: [CommonModule],
	exports: [ThemeToggleComponent],
})
export class ThemeToggleModule {}
