<div class="stripe-grid">
  <div class="stripe-grid__container">
    <div class="stripe-grid__grid">
      <div class="stripe s1 green1"></div>
      <div class="stripe s2 green2"></div>
      <div class="stripe s3 green3"></div>
      <div class="stripe s4 green4"></div>
      <div class="stripe s5 green2"></div>
      <div class="stripe s6 green4"></div>
      <div class="stripe s7 green2"></div>
      <div class="stripe s8 green1"></div>
      <div class="stripe s9 green4"></div>
      <div class="stripe s10 green5"></div>
      <div class="stripe s11 green1"></div>
      <div class="stripe s12 green5"></div>
      <div class="stripe s13 green1"></div>
      <div class="stripe s14 green4"></div>
      <div class="stripe s15 green1"></div>
      <div class="stripe s16 green2"></div>
      <div class="stripe s17 green1"></div>
      <div class="stripe s18 green5"></div>
      <div class="stripe s19 green3"></div>
      <div class="stripe s20 green1"></div>
      <div class="stripe s21 green4"></div>
      <div class="stripe s22 green1"></div>
      <div class="stripe s23 green5"></div>
      <div class="stripe s24 green3"></div>
      <div class="stripe s25 green5"></div>
      <div class="stripe s26 green1"></div>
    </div>
  </div>
</div>
