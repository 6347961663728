import { Injectable, Optional } from '@angular/core';
import {
  Auth,
  User as FirebaseUser,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  authState,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@angular/fire/auth';
import { Firestore, doc, docData, setDoc } from '@angular/fire/firestore';
import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { BoardMember } from './../../_objects/board-member';
import { Employee } from './../../_objects/employee';
import { User } from './../../_objects/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: Observable<User | Employee | BoardMember> = EMPTY;

  public constructor(
    @Optional() private _auth: Auth,
    @Optional() private _firestore: Firestore
  ) {
    if (this._auth) {
      this.user = authState(this._auth).pipe(
        switchMap((user: FirebaseUser | null) => {
          if (user) {
            return docData(doc(this._firestore, `users/${user.uid}`)) as Observable<
              User | Employee | BoardMember
            >;
          } else {
            return EMPTY;
          }
        })
      );
    }
  }

  public async signInWithGoogle(): Promise<void | UserCredential> {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(this._auth, provider).then((creds: UserCredential) =>
      this._updateUserData(creds.user as User | Employee | BoardMember)
    );
  }

  public async signInWithMicrosoft(): Promise<void | UserCredential> {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: environment.rrcuTenantID,
    });
    return await signInWithPopup(this._auth, provider).then((creds: UserCredential) =>
      this._updateUserData(creds.user as User | Employee | BoardMember)
    );
  }

  public async signInWithEmailAndPassword(
    email: string,
    password: string,
    remember: boolean = false
  ): Promise<void | UserCredential> {
    return signInWithEmailAndPassword(this._auth, email, password).then(
      (creds: UserCredential) =>
        this._updateUserData(creds.user as User | Employee | BoardMember)
    );
  }

  public async sendPasswordReset(email: string): Promise<void> {
    return sendPasswordResetEmail(this._auth, email);
  }

  public async resetPassword(code: string, password: string): Promise<void> {
    return confirmPasswordReset(this._auth, code, password);
  }

  public signOut(): Promise<void> {
    this.user = EMPTY;

    return this._auth.signOut();
  }

  private async _updateUserData(user: User | Employee | BoardMember): Promise<void> {
    return setDoc(
      doc(this._firestore, `users/${user.uid}`),
      {
        uid: user.uid,
        // email: user.email,
        // displayName: user.displayName,
        // photoURL: user.photoURL,
      } as User,
      { merge: true }
    );
  }
}
