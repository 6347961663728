import { Injectable, OnDestroy, Optional } from '@angular/core';
import {
	Firestore,
	collection,
	collectionData,
	doc,
	updateDoc,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, mergeAll, share } from 'rxjs/operators';

import { Rate } from './../../_objects/rate';
import { RateCategory } from './../../_objects/rate-category';
import { RateCategoryNames } from './../../_objects/rate-names';

@Injectable({
	providedIn: 'root',
})
export class RateService implements OnDestroy {
	public categories: BehaviorSubject<RateCategory[]> = new BehaviorSubject<
		RateCategory[]
	>([]);

	/** The latest categories that were emitted from the database. */
	public get categories$(): Observable<RateCategory[]> {
		return this.categories.asObservable();
	}

	public get auto(): Observable<RateCategory> {
		return this.categories$.pipe(
			map((categories: RateCategory[]) =>
				categories.filter(
					(category: RateCategory) => category.name === RateCategoryNames.Vehicle
				)
			),
			mergeAll()
		);
	}

	public get realEstate(): Observable<RateCategory> {
		return this.categories$.pipe(
			map((categories: RateCategory[]) =>
				categories.filter(
					(category: RateCategory) => category.name === RateCategoryNames.RealEstate
				)
			),
			mergeAll()
		);
	}

	public get personalLoan(): Observable<RateCategory> {
		return this.categories$.pipe(
			map((categories: RateCategory[]) =>
				categories.filter(
					(category: RateCategory) => category.name === RateCategoryNames.Personal
				)
			),
			mergeAll()
		);
	}

	public get creditCards(): Observable<RateCategory> {
		return this.categories$.pipe(
			map((categories: RateCategory[]) =>
				categories.filter(
					(category: RateCategory) => category.name === RateCategoryNames.CreditCards
				)
			),
			mergeAll()
		);
	}

	public get certificates(): Observable<RateCategory> {
		return this.categories$.pipe(
			map((categories: RateCategory[]) =>
				categories.filter(
					(category: RateCategory) => category.name === RateCategoryNames.Certificates
				)
			),
			mergeAll()
		);
	}

	private _subs: Subscription[] = [];

	public constructor(@Optional() private _firestore: Firestore) {
		if (this._firestore) {
			this._subs.push(
				collectionData(collection(this._firestore, 'rates'), { idField: 'uid' })
					.pipe(share())
					.subscribe((categories) => this.categories.next(categories as RateCategory[]))
			);
		}
	}

	public ngOnDestroy(): void {
		this._subs.forEach((sub: Subscription) => sub.unsubscribe());
	}

	public getRatesByCategory(
		category: Observable<RateCategory | Rate[]>
	): Observable<Rate[]> {
		return category?.pipe(
			map((cat: RateCategory | Rate[]) => (cat as RateCategory).rates as Rate[])
		);
	}

	public getRateByName(
		name: string,
		category: Observable<RateCategory | Rate[]>
	): Observable<Rate> {
		return this.getRatesByCategory(category).pipe(
			mergeAll(),
			filter((rate: Rate) => rate.name === name || rate?.term === name)
		);
	}

	public updateCategory(uid: string, category: RateCategory): Promise<void> {
		return updateDoc(doc(this._firestore, `rates/${uid}`), { ...category });
	}
}
