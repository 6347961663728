import { ApplicationRef, Injectable, Optional } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable, concat, filter, interval, map } from 'rxjs';
import { first } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UpdateService {
	public isUpdateAvailable = false;

	public constructor(
		public appRef: ApplicationRef,
		@Optional() public updates: SwUpdate
	) {
		if (environment.production && this.updates) {
			// Allow the app to stabilize first, before starting polling for updates with `interval()`.
			const appIsStable$: Observable<boolean> = appRef.isStable.pipe(
				first((isStable) => isStable === true)
			);
			const everyThirtyMinutes$: Observable<number> = interval(1 * 30 * 60 * 1000);
			const everyThirtyMinutesOnceAppIsStable$: Observable<boolean | number> = concat(
				appIsStable$,
				everyThirtyMinutes$
			);

			everyThirtyMinutesOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

			this.updates.versionUpdates
				.pipe(
					filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
					map((evt) => ({
						type: 'UPDATE_AVAILABLE',
						current: evt.currentVersion,
						available: evt.latestVersion,
					}))
				)
				.subscribe((event) => {
					this.isUpdateAvailable = true;
				});
		}
	}
}
