import { NgModule } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { AppModule } from './app.module';
import { AppComponent } from './app/app.component';
import { connectAuthEmulatorInDevMode } from './emulators';

@NgModule({
  imports: [
    AppModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideRemoteConfig(() => getRemoteConfig()),
    // provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      });

      if (environment.useEmulators) {
        connectAuthEmulatorInDevMode(auth);
      }

      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();

      // Check if the Firestore instance has already been initialized (common with HMR)
      if (!(firestore as any)['_initialized']) {
        if (environment.useEmulators) {
          connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
      }

      return firestore;
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule { }
