import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'xcu-invo-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invo-widget.component.html',
  styleUrls: ['./invo-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvoWidgetComponent implements OnInit, AfterViewInit {
  @HostBinding('class.scrolled-bottom')
  private _atBottom: boolean = false;

  public constructor(
    private _platform: Platform,
    private _renderer: Renderer2,
    private _el: ElementRef,
  ) { }

  public ngOnInit(): void {
    if (this._platform.isBrowser) {
      const script = this._renderer.createElement('script');
      script.defer = true;

      const scriptContent = this._renderer.createText(`
      (function (i, n, v, o, s, l, t) {
        i[o + 'URL'] = s; i[o] = i[o] || { init: function () { i[o].q = arguments } }; l = n.createElement(v);
        t = n.getElementsByTagName(v)[0]; l.async = 1; l.src = '/assets/vendor/js/widget.js'; t.parentNode.insertBefore(l, t);
      })
        (window, document, 'script', 'InvoWidget', 'https://video.multiplicu.com');

      InvoWidgetOptions = {
        primaryColor: "var(--theme--secondary)",
        icons: {
          chat: '/assets/icons/chat/chat.svg',
          close: '/assets/icons/chat/close.svg',
        }
      }
    `);

      this._renderer.appendChild(script, scriptContent);
      this._renderer.appendChild(document.head, script);
    }
  }

  public ngAfterViewInit(): void {
    if (!this._platform.isBrowser) {
      return
    }

    const sendSvg: HTMLElement = this._el.nativeElement?.querySelector('[aria-labelledby="Send"]');

    const sendSvgG: SVGElement | null = sendSvg?.querySelector('g');
    sendSvgG?.setAttribute('path', '<path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />');
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll() {
    // Calculate the scroll position
    let pos: number =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      window.innerHeight;
    let max: number = document.documentElement.scrollHeight;

    this._atBottom = pos >= max;
  }
}
