import { Component } from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { EMPTY, Observable, from } from 'rxjs';

import { environment } from './../../../../environments/environment';

@Component({
  selector: 'xcu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public appName: string = environment.title;
  public copyrightYear: number = new Date().getFullYear();
  public scholarshipLink$: Observable<string> = EMPTY;

  public constructor(private _storage: Storage) {
    this.scholarshipLink$ = from(
      getDownloadURL(ref(this._storage, `pdf/Franklin O Walstrand Scholarship.pdf`))
    );
  }
}
