import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	public theme!: 'light' | 'dark';

	public get isLight(): boolean {
		return this.theme === 'light';
	}

	public get isDark(): boolean {
		return !this.isLight;
	}

	public constructor(private _platform: Platform) {
		if (this._platform.isBrowser && window?.localStorage) {
			// Set theme color
			const storedTheme: string =
				localStorage.getItem('theme') ||
				(!('theme' in localStorage) &&
				window.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark'
					: 'light');

			if (storedTheme === 'light' || storedTheme === 'dark') {
				this.theme = storedTheme;

				this._setDataAttribute();
			}

			try {
				window
					.matchMedia('(prefers-color-scheme: dark)')
					.addEventListener('change', (e: MediaQueryListEvent) => {
						this.theme = e.matches ? 'dark' : 'light';

						this._setDataAttribute();
					});
			} catch (error) {
				// Used for browsers that would error on newer `addEventListener` function
				window
					.matchMedia('(prefers-color-scheme: dark)')
					.addListener((e: MediaQueryListEvent) => {
						this.theme = e.matches ? 'dark' : 'light';

						this._setDataAttribute();
					});
			}
		}
	}

	public toggle(): void {
		this.theme = this.isLight ? 'dark' : 'light';

		this._setDataAttribute();
	}

	private _setDataAttribute(): void {
		localStorage.setItem('theme', this.theme);

		document.documentElement.setAttribute('data-theme', this.theme);
	}
}
