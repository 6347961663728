import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  CustomProvider,
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  provideAppCheck,
} from '@angular/fire/app-check';
import { AuthGuard } from '@angular/fire/auth-guard';
import { FunctionsModule } from '@angular/fire/functions';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XcuAvatarModule } from '@multiplicu/ui/avatar';
import { XcuBadgeModule } from '@multiplicu/ui/badge';
import { XcuButtonModule } from '@multiplicu/ui/button';
import { XcuBannerModule } from '@multiplicu/ui/feedback/banner';
import { XcuMegaMenuModule } from '@multiplicu/ui/navigation/mega-menu';
import { XcuSwitcherModule } from '@multiplicu/ui/navigation/switcher';
import type { app } from 'firebase-admin';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { InvoWidgetComponent } from './layouts/components/invo-widget/invo-widget.component';
import { ThemeToggleModule } from './layouts/components/theme-toggle/theme-toggle.module';
import { FooterComponent } from './layouts/public/footer/footer.component';
import { HeaderComponent } from './layouts/public/header/header.component';
import { PublicComponent } from './layouts/public/public.component';
import { StripeSeparatorModule } from './layouts/public/stripe-separator/stripe-separator.module';

export const FIREBASE_ADMIN = new InjectionToken<app.App>('firebase-admin');

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FunctionsModule,
    HttpClientModule,
    ReactiveFormsModule,
    StripeSeparatorModule,
    XcuButtonModule,
    XcuMegaMenuModule,
    XcuAvatarModule,
    XcuSwitcherModule,
    XcuBannerModule,
    XcuBadgeModule,
    ThemeToggleModule,
    InvoWidgetComponent,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAppCheck(
      (injector) => {
        const platformId: Object | null = injector.get<Object | null>(
          PLATFORM_ID,
          null,
        );

        if (!environment.production) {
          (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
        }

        const admin = injector.get<app.App | null>(FIREBASE_ADMIN, null);
        if (admin) {
          const provider = new CustomProvider({
            getToken: () =>
              admin
                .appCheck()
                .createToken(environment.firebaseConfig.appId, {
                  ttlMillis: 604_800_000 /* 1 week */,
                })
                .then(({ token, ttlMillis: expireTimeMillis }) => ({
                  token,
                  expireTimeMillis,
                })),
          });
          return initializeAppCheck(undefined, {
            provider,
            isTokenAutoRefreshEnabled: false,
          });
        } else {
          const provider = new ReCaptchaEnterpriseProvider(
            environment.recaptcha3SiteKey,
          );
          return initializeAppCheck(undefined, {
            provider,
            isTokenAutoRefreshEnabled: true,
          });
        }
      },
      [new Optional(), FIREBASE_ADMIN],
    ),
    Title,
    AuthGuard,
  ],
  bootstrap: [],
})
export class AppModule { }
